import { Routes, Route, Outlet, Navigate } from 'react-router';
import { lazy, Suspense } from 'react';

import { CircularProgress } from '@mui/material';
import Home from './pages/Home';

const Calendar = lazy(() => import('./pages/Calendar'));
const EfaxPage = lazy(() => import('./pages/Efax'));
const Error = lazy(() => import('./pages/Error'));
const ErrorBoundary = lazy(() => import('./components/ErrorBoundary'));
const MembersAndBilling = lazy(() => import('./pages/MembersAndBilling'));
const Note = lazy(() => import('./pages/Note'));
const PatientProfile = lazy(() => import('./features/patients/PatientProfile'));
const Patients = lazy(() => import('./pages/Patients'));
const Payments = lazy(() => import('./pages/Payments'));
const PracticeDocuments = lazy(
  () => import('./features/documents/PracticeDocuments')
);
const PracticeSettings = lazy(() => import('./pages/PracticeSettings'));
const Prescriptions = lazy(() => import('./features/dosespot/Prescriptions'));
const SignOutRedirecter = lazy(
  () => import('./authentication/SignOutRedirecter')
);
const Messages = lazy(() => import('./pages/Messages'));

const Router = () => {
  // NOTE: Since this error boundary sits just a level above the other
  // set of routes, the `navigate` router event won't be able to reach
  // the destination route (`/`) for errors other than 404.
  //
  // To work around this, it instead does an `href` replacement, which
  // triggers a full navigation (with page load) back to the homepage.
  //
  // This, however, introduces a small -but noticeable- flicker due to
  // the browser having loading the page from scratch, instead of just
  // replacing the current DOM.
  //
  // In the future, we may revisit this approach, along with the route
  // definition strategy and improve the structure in order to support
  // route-level layouts, leverage the route's `errorElement` prop for
  // error handling & more.
  const ErrorWrapper = () => (
    <ErrorBoundary fallback={<Error type={500} />}>
      <Outlet />
    </ErrorBoundary>
  );

  const Loader = () => {
    return (
      <div className="flex flex-col justify-center items-center h-full w-full">
        <CircularProgress />
      </div>
    );
  };

  const NavigateToReturnUrl = ({ fallback }: { fallback: string }) => {
    const returnTo = new URLSearchParams(window.location.search).get(
      'return_to'
    );

    return <Navigate to={returnTo ?? fallback} replace />;
  };

  return (
    <Routes>
      <Route
        element={
          <Suspense fallback={<Loader />}>
            <ErrorWrapper />
          </Suspense>
        }
      >
        <Route
          path="/"
          element={
            <Suspense fallback={<Loader />}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/guest/form/:token"
          element={
            <Suspense fallback={<Loader />}>
              <SignOutRedirecter />
            </Suspense>
          }
        />
        <Route
          path="/guest/consent/:token"
          element={
            <Suspense fallback={<Loader />}>
              <SignOutRedirecter />
            </Suspense>
          }
        />
        <Route
          path="/signup"
          element={
            <Suspense fallback={<Loader />}>
              <SignOutRedirecter />
            </Suspense>
          }
        />
        <Route
          path="/calendar"
          element={
            <Suspense fallback={<Loader />}>
              <Calendar />
            </Suspense>
          }
        />
        <Route
          path="/patients"
          element={
            <Suspense fallback={<Loader />}>
              <Patients />
            </Suspense>
          }
        />
        <Route
          path="/patientProfile/:patient"
          element={
            <Suspense fallback={<Loader />}>
              <PatientProfile />
            </Suspense>
          }
        />
        <Route
          path="/encounter/:patient/:encounterId?"
          element={
            <Suspense fallback={<Loader />}>
              <Note />
            </Suspense>
          }
        />
        <Route
          path="/payments"
          element={
            <Suspense fallback={<Loader />}>
              <Payments />
            </Suspense>
          }
        />
        <Route
          path="/documents"
          element={
            <Suspense fallback={<Loader />}>
              <PracticeDocuments />
            </Suspense>
          }
        />
        <Route
          path="/prescription"
          element={
            <Suspense fallback={<Loader />}>
              <Prescriptions />
            </Suspense>
          }
        />
        <Route
          path="/members-and-billing"
          element={
            <Suspense fallback={<Loader />}>
              <MembersAndBilling />
            </Suspense>
          }
        />
        <Route
          path="/signin"
          element={
            <Suspense fallback={<Loader />}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/practice"
          element={
            <Suspense fallback={<Loader />}>
              <PracticeSettings />
            </Suspense>
          }
        />
        <Route
          path="/efax"
          element={
            <Suspense fallback={<Loader />}>
              <EfaxPage />
            </Suspense>
          }
        />
        <Route
          path="/messages"
          element={
            <Suspense fallback={<Loader />}>
              <Messages />
            </Suspense>
          }
        />

        <Route
          path="/confirmation"
          element={<NavigateToReturnUrl fallback="/" />}
        />
        <Route
          path="/checkout"
          element={<NavigateToReturnUrl fallback="/" />}
        />

        <Route path="*" element={<Error type={404} />}></Route>
      </Route>
    </Routes>
  );
};

export default Router;
