import Typography from '../../../components/Typography';
import {
  SocialInfoFields,
  useSocialInfoForm,
} from '../hooks/useSocialInfoForm';
import { CircularProgress, FormControl } from '@mui/material';
import { Label } from '@aster/client/ui/Label/Label';
import {
  RadioGroup,
  RadioIndicator,
  RadioItem,
} from '@aster/client/ui/Radio/Radio';
import { Input } from '@aster/client/ui/Input/Input';
import { Checkbox } from '@aster/client/ui/Checkbox/Checkbox';
import { defaultNationalOrLocalPrograms } from '../defaults';
import { isPatientReadonly } from '../utils/is-patient-readonly';
import { useDebouncedCallback } from 'use-debounce';
import { Profile, useAuth } from '../../../authentication/AuthProvider';
import { useUserUsagePreferences } from '@aster/shared/shared/client/utils';
import { LAST_SAVED_SOCIAL_INFORMATION } from '../constants';
import dayjs from 'dayjs';
import { ProfileSaveButton } from '../profileTabs/components/ProfileSaveButton';
import { forwardRef, useImperativeHandle, useState } from 'react';
import timeSinceLastSaved from '../utils/timeSinceLastSaved';
import { PatientInfoDTO } from '@aster/app/core/shared/dtos/patient';

const SocialInformation = forwardRef(
  (
    {
      updatePatientMutation,
      patientInfo,
      isPatientLoading,
    }: {
      patientInfo: PatientInfoDTO | undefined;
      updatePatientMutation: any;
      isPatientLoading: boolean;
    },
    ref
  ) => {
    const [debounceValue, setDebounceValue] = useState<number>(1000);
    useImperativeHandle(ref, () => ({
      submit: async () => {
        setDebounceValue(0);
        await socialInfoForm.handleSubmit();
      },
    }));

    const { profile } = useAuth();
    const { storePreference, readPreference } = useUserUsagePreferences(
      profile as Profile
    );

    const lastSaved = readPreference<string>(LAST_SAVED_SOCIAL_INFORMATION);

    const lastSavedString = lastSaved ? timeSinceLastSaved(lastSaved) : '';

    const socialInfoForm = useSocialInfoForm({
      defaultValues: patientInfo as PatientInfoDTO,
      onSubmit: (value) =>
        save(value)?.then(() => {
          storePreference(LAST_SAVED_SOCIAL_INFORMATION, dayjs().toISOString());
          readPreference<string>(LAST_SAVED_SOCIAL_INFORMATION);
          setDebounceValue(1000);
        }),
    });

    const save = useDebouncedCallback(
      async (value: Partial<SocialInfoFields>) => {
        await updatePatientMutation.mutateAsync(value);
      },
      debounceValue
    );

    const readonly = isPatientReadonly(patientInfo);

    return (
      <>
        <ProfileSaveButton
          lastSavedString={lastSavedString}
          updatePatientMutation={updatePatientMutation}
          form={socialInfoForm}
        />
        <div className="container w-full pb-20">
          {isPatientLoading ? (
            <CircularProgress />
          ) : (
            <form
              onSubmit={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                void socialInfoForm.handleSubmit();
              }}
            >
              <div className="flex flex-wrap h-fit gap-10 xl:gap-40">
                <div className="flex flex-col gap-y-5 w-full sm:w-auto">
                  <Typography
                    text="Social Information"
                    variant="h5"
                    customClass="font-semibold"
                  ></Typography>
                  <socialInfoForm.Field
                    name="hasSocialSupport"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          Do you have social support available?
                        </Label>
                        <RadioGroup
                          className="gap-2 flex flex-col items-start"
                          value={field.state.value ?? undefined}
                          onValueChange={(value) => {
                            field.handleChange(
                              value as 'yes' | 'sometimes' | 'no'
                            );
                            void socialInfoForm.handleSubmit();
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-yes`} value="yes">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes I feel supported
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem
                              id={`${field.name}-sometimes`}
                              value="sometimes"
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-sometimes`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Sometimes
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-no`} value="no">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-no`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              No
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  <socialInfoForm.Field
                    name="employmentStatus"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          Are you currently employed?
                        </Label>
                        <RadioGroup
                          className="flex flex-col items-start gap-2"
                          value={field.state.value ?? undefined}
                          onValueChange={(value) => {
                            field.handleChange(
                              value as
                                | 'full-time'
                                | 'part-time'
                                | 'unemployed'
                                | 'full-time-student'
                                | 'part-time-student'
                            );
                            void socialInfoForm.handleSubmit();
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <RadioItem
                              id={`${field.name}-full-time`}
                              value="full-time"
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-full-time`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes full time
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem
                              id={`${field.name}-part-time`}
                              value="part-time"
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-part-time`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes part time
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem
                              id={`${field.name}-unemployed`}
                              value="unemployed"
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-unemployed`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Unemployed
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem
                              id={`${field.name}-full-time-student`}
                              value="full-time-student"
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-full-time-student`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Full time student
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem value="part-time-student">
                              <RadioIndicator />
                            </RadioItem>
                            <Label className="m-0 cursor-pointer text-aster-secondary font-normal">
                              Part-time student
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  <socialInfoForm.Field
                    name="doFeelSafe"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">Do you feel safe?</Label>
                        <RadioGroup
                          className="gap-4"
                          value={field.state.value ?? undefined}
                          onValueChange={(value) => {
                            field.handleChange(
                              value as 'yes' | 'somewhat' | 'no'
                            );
                            void socialInfoForm.handleSubmit();
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-yes`} value="yes">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem
                              id={`${field.name}-somewhat`}
                              value="somewhat"
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-somewhat`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Somewhat
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-no`} value="no">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-no`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              No
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  <socialInfoForm.Field
                    name="doFeelFinanciallySecure"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          Do you feel financially secure?
                        </Label>
                        <RadioGroup
                          className="gap-4"
                          value={field.state.value ?? undefined}
                          onValueChange={(value) => {
                            field.handleChange(
                              value as 'yes' | 'for_now' | 'no'
                            );
                            void socialInfoForm.handleSubmit();
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-yes`} value="yes">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem
                              id={`${field.name}-for_now`}
                              value="for_now"
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-for_now`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes for now
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-no`} value="no">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-no`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              No
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  <socialInfoForm.Field
                    name="doHaveAdequateTransportation"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          Do you have adequate transportation to get to medical
                          appointments?
                        </Label>
                        <RadioGroup
                          className="gap-4"
                          value={field.state.value ?? undefined}
                          onValueChange={(value) => {
                            field.handleChange(
                              value as 'yes' | 'sometimes' | 'no'
                            );
                            void socialInfoForm.handleSubmit();
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-yes`} value="yes">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem
                              id={`${field.name}-sometimes`}
                              value="sometimes"
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-sometimes`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Sometimes
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-no`} value="no">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-no`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              No
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  <socialInfoForm.Field
                    name="housingSituation"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          What is your housing situation today?
                        </Label>
                        <RadioGroup
                          className="flex flex-col items-start gap-2"
                          value={field.state.value ?? undefined}
                          onValueChange={(value) => {
                            field.handleChange(
                              value as
                                | 'has_housing'
                                | 'not_has_housing'
                                | 'worried_about_future'
                                | 'staying_with_family_or_friends'
                            );
                            void socialInfoForm.handleSubmit();
                          }}
                        >
                          <div className="flex items-center gap-2">
                            <RadioItem
                              id={`${field.name}-has_housing`}
                              value="has_housing"
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-has_housing`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              I have housing
                            </Label>
                          </div>
                          <div className="flex items-center gap-2">
                            <RadioItem
                              id={`${field.name}-not_has_housing`}
                              value="not_has_housing"
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-not_has_housing`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              I do not have housing
                            </Label>
                          </div>
                          <div className="flex items-center gap-2">
                            <RadioItem
                              id={`${field.name}-worried_about_future`}
                              value="worried_about_future"
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-worried_about_future`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              I have housing but I am worried about not having
                              this in future
                            </Label>
                          </div>
                          <div className="flex items-center gap-2">
                            <RadioItem
                              id={`${field.name}-staying_with_family_or_friends`}
                              value="staying_with_family_or_friends"
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-staying_with_family_or_friends`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              I am staying with friends/family
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  <socialInfoForm.Field
                    name="doHaveEnoughHealthyFood"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          Are you worried about access to food?
                        </Label>
                        <RadioGroup
                          className="gap-4"
                          value={
                            field.state.value === true
                              ? 'yes'
                              : field.state.value === false
                              ? 'no'
                              : undefined
                          }
                          onValueChange={(value) => {
                            const doHaveEnoughHealthyFood = value === 'yes';
                            field.handleChange(doHaveEnoughHealthyFood);
                            void socialInfoForm.handleSubmit();
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-yes`} value="yes">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-no`} value="no">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-no`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              No
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  {socialInfoForm.state.values.doHaveEnoughHealthyFood && (
                    <socialInfoForm.Field
                      name="lackOfHealthyFoodDetails"
                      children={(field) => (
                        <Input
                          className="max-w-[250px]"
                          disabled={readonly}
                          placeholder="Details"
                          name="lackOfHealthyFoodDetails"
                          value={field.state.value ?? ''}
                          onChange={(e) => field.handleChange(e.target.value)}
                          onBlur={(evt) => {
                            if (field.state.meta.isDirty) {
                              void socialInfoForm.handleSubmit();
                            }
                          }}
                        />
                      )}
                    />
                  )}
                  <socialInfoForm.Field
                    name="nationalOrLocalPrograms"
                    children={(field) => (
                      <FormControl className="flex gap-2">
                        <Label>
                          Are you part of any local or national level programs?
                        </Label>
                        <div className="flex flex-wrap gap-4">
                          <div className="flex flex-col gap-2">
                            {defaultNationalOrLocalPrograms
                              .slice(0, 3)
                              .map((program) => (
                                <div
                                  key={program.value}
                                  className="flex gap-2 relative"
                                >
                                  <Checkbox
                                    id={`${field.name}-${program.value}`}
                                    key={program.value}
                                    checked={
                                      field.state.value?.find(
                                        (item) => item.value === program.value
                                      )?.checked
                                    }
                                    onCheckedChange={(value) => {
                                      if (value === 'indeterminate') return;
                                      const updatedItems =
                                        field.state.value?.map((item) =>
                                          item.value === program.value
                                            ? { ...item, checked: value }
                                            : item
                                        );
                                      if (updatedItems) {
                                        field.handleChange(updatedItems);
                                        void socialInfoForm.handleSubmit();
                                      }
                                    }}
                                    disabled={readonly}
                                  />
                                  <Label
                                    className="cursor-pointer text-aster-secondary font-normal"
                                    htmlFor={`${field.name}-${program.value}`}
                                  >
                                    {program.text}
                                  </Label>
                                </div>
                              ))}
                          </div>
                          <div className="flex flex-col gap-2">
                            {defaultNationalOrLocalPrograms
                              .slice(3, 6)
                              .map((program) => (
                                <div
                                  key={program.value}
                                  className="flex gap-2 relative"
                                >
                                  <Checkbox
                                    id={`${field.name}-${program.value}`}
                                    key={program.value}
                                    checked={
                                      field.state.value?.find(
                                        (item) => item.value === program.value
                                      )?.checked
                                    }
                                    onCheckedChange={(value) => {
                                      if (value === 'indeterminate') return;
                                      const updatedItems =
                                        field.state.value?.map((item) =>
                                          item.value === program.value
                                            ? { ...item, checked: value }
                                            : item
                                        );
                                      if (updatedItems) {
                                        field.handleChange(updatedItems);
                                        void socialInfoForm.handleSubmit();
                                      }
                                    }}
                                    disabled={readonly}
                                  />
                                  <Label
                                    className="cursor-pointer text-aster-secondary font-normal"
                                    htmlFor={`${field.name}-${program.value}`}
                                  >
                                    {program.text}
                                  </Label>
                                </div>
                              ))}
                          </div>
                        </div>
                      </FormControl>
                    )}
                  />
                  <socialInfoForm.Field
                    name="doFeelSafeOnCurrentRelationship"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          Do you feel safe in your current relationship?
                        </Label>
                        <RadioGroup
                          className="gap-4"
                          value={field.state.value ?? undefined}
                          onValueChange={(value) => {
                            field.handleChange(
                              value as 'yes' | 'somewhat' | 'no' | 'na'
                            );
                            void socialInfoForm.handleSubmit();
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-yes`} value="yes">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem
                              id={`${field.name}-somewhat`}
                              value="somewhat"
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-somewhat`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Somewhat
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-no`} value="no">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-no`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              No
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-na`} value="na">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-na`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              N/A
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  <socialInfoForm.Field
                    name="isPartnerControlling"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          Does your partner ever try to control what you do, who
                          you see or talk to, or where you go?
                        </Label>
                        <RadioGroup
                          className="gap-4"
                          value={field.state.value ?? undefined}
                          onValueChange={(value) => {
                            field.handleChange(
                              value as 'yes' | 'somewhat' | 'no' | 'na'
                            );
                            void socialInfoForm.handleSubmit();
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-yes`} value="yes">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes
                            </Label>
                          </div>
                          <RadioItem
                            id={`${field.name}-somewhat`}
                            value="somewhat"
                          >
                            <RadioIndicator />
                          </RadioItem>
                          <Label
                            htmlFor={`${field.name}-somewhat`}
                            className="m-0 cursor-pointer text-aster-secondary font-normal"
                          >
                            Somewhat
                          </Label>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-no`} value="no">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-no`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              No
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-na`} value="na">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-na`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              N/A
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  <socialInfoForm.Field
                    name="doHaveConcernsAboutChildSafety"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          Do you have any concerns about the safety of your
                          children?
                        </Label>
                        <RadioGroup
                          className="gap-4"
                          value={field.state.value ?? undefined}
                          onValueChange={(value) => {
                            field.handleChange(
                              value as 'yes' | 'somewhat' | 'no' | 'na'
                            );
                            void socialInfoForm.handleSubmit();
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-yes`} value="yes">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem
                              id={`${field.name}-somewhat`}
                              value="somewhat"
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-somewhat`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Somewhat
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-no`} value="no">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-no`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              No
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-na`} value="na">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-na`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              N/A
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  <socialInfoForm.Field
                    name="hasPartnerForcedSexually"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          Has your partner ever forced you into sexual
                          activities that you did not want?
                        </Label>
                        <RadioGroup
                          className="gap-4"
                          value={field.state.value ?? undefined}
                          onValueChange={(value) => {
                            field.handleChange(
                              value as 'yes' | 'somewhat' | 'no' | 'na'
                            );
                            void socialInfoForm.handleSubmit();
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-yes`} value="yes">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem
                              id={`${field.name}-somewhat`}
                              value="somewhat"
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-somewhat`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Somewhat
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-no`} value="no">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-no`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              No
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-na`} value="na">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-na`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              N/A
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  <socialInfoForm.Field
                    name="hasPartnerEverThreatenedOrMadeFeelUnsafe"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          Has your current partner ever threatened you or made
                          you feel afraid?
                        </Label>
                        <RadioGroup
                          className="gap-4"
                          value={field.state.value ?? undefined}
                          onValueChange={(value) => {
                            field.handleChange(
                              value as 'yes' | 'somewhat' | 'no' | 'na'
                            );
                            void socialInfoForm.handleSubmit();
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-yes`} value="yes">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem
                              id={`${field.name}-somewhat`}
                              value="somewhat"
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-somewhat`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Somewhat
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-no`} value="no">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-no`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              No
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-na`} value="na">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-na`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              N/A
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  <socialInfoForm.Field
                    name="hasPreviousPartnerAbusiveExperience"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          Have you previously experienced violence, aggression
                          or controlling behavior from a partner?
                        </Label>
                        <RadioGroup
                          className="gap-4"
                          value={
                            field.state.value === true
                              ? 'yes'
                              : field.state.value === false
                              ? 'no'
                              : undefined
                          }
                          onValueChange={(value) => {
                            const hasPreviousPartnerAbusiveExperience =
                              value === 'yes';
                            field.handleChange(
                              hasPreviousPartnerAbusiveExperience
                            );
                            void socialInfoForm.handleSubmit();
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-yes`} value="yes">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="gap-2 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-no`} value="no">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-no`}
                              className="gap-2 cursor-pointer text-aster-secondary font-normal"
                            >
                              No
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  {socialInfoForm.state.values
                    .hasPreviousPartnerAbusiveExperience === true && (
                    <socialInfoForm.Field
                      name="previousPartnerAbusiveExperienceDetails"
                      children={(field) => (
                        <FormControl>
                          <Input
                            disabled={readonly}
                            placeholder="Details"
                            name="previousPartnerAbusiveExperienceDetails"
                            value={field.state.value ?? ''}
                            onChange={(e) => field.handleChange(e.target.value)}
                            onBlur={(evt) => {
                              if (field.state.meta.isDirty) {
                                void socialInfoForm.handleSubmit();
                              }
                            }}
                          />
                        </FormControl>
                      )}
                    />
                  )}
                </div>
              </div>
            </form>
          )}
        </div>
      </>
    );
  }
);

export default SocialInformation;
