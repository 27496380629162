import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Typography from '../../../components/Typography';
import dayjs from 'dayjs';
import { Input } from '@aster/client/ui/Input/Input';
import { FormControl } from '@aster/client/ui/FormControl/FormControl';
import { InputMask } from '@react-input/mask';
import { FormError } from '@aster/client/ui/FormControl/FormError';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@aster/client/ui/Select/Select';
import { CircularProgress } from '@mui/material';
import {
  PersonalInfoFields,
  usePersonalInfoForm,
} from '../hooks/usePersonalInfoForm';
import { isPatientReadonly } from '../utils/is-patient-readonly';
import { useDebouncedCallback } from 'use-debounce';
import { LAST_SAVED_PERSONAL_INFORMATION } from '../constants';
import { ProfileSaveButton } from '../profileTabs/components/ProfileSaveButton';
import { Label } from '@aster/client/ui/Label/Label';
import { forwardRef, useImperativeHandle, useState } from 'react';
import timeSinceLastSaved from '../utils/timeSinceLastSaved';
import { LegalSex, PatientInfoDTO } from '@aster/app/core/shared/dtos/patient';
import { Profile, useAuth } from '../../../authentication/AuthProvider';
import { useUserUsagePreferences } from '@aster/shared/shared/client/utils';

const PersonalInformation = forwardRef(
  (
    {
      updatePatientMutation,
      patientInfo,
      isPatientLoading,
    }: {
      patientInfo: PatientInfoDTO | undefined;
      updatePatientMutation: any;
      isPatientLoading: boolean;
    },
    ref
  ) => {
    const [debounceValue, setDebounceValue] = useState<number>(1000);
    useImperativeHandle(ref, () => ({
      submit: async () => {
        setDebounceValue(0);
        await personalInfoForm.handleSubmit();
      },
    }));

    const { profile } = useAuth();
    const { storePreference, readPreference } = useUserUsagePreferences(
      profile as Profile
    );

    const lastSaved = readPreference<string>(LAST_SAVED_PERSONAL_INFORMATION);

    const lastSavedString = lastSaved ? timeSinceLastSaved(lastSaved) : '';

    const personalInfoForm = usePersonalInfoForm({
      defaultValues: patientInfo as PatientInfoDTO,
      onSubmit: (value) =>
        save({
          ...value,
          dateOfBirth: value.dateOfBirth
            ? dayjs(value.dateOfBirth).toISOString()
            : null,
        })?.then(() => {
          storePreference(
            LAST_SAVED_PERSONAL_INFORMATION,
            dayjs().toISOString()
          );
          setDebounceValue(1000);
        }),
    });

    const save = useDebouncedCallback(
      async (value: Partial<PersonalInfoFields>) => {
        await updatePatientMutation.mutateAsync(value);
      },
      debounceValue
    );

    const readonly = isPatientReadonly(patientInfo);

    return (
      <>
        <ProfileSaveButton
          lastSavedString={lastSavedString}
          updatePatientMutation={updatePatientMutation}
          form={personalInfoForm}
        />
        <div className="container w-full pb-20">
          {isPatientLoading ? (
            <CircularProgress />
          ) : (
            <form
              onSubmit={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                void personalInfoForm.handleSubmit();
              }}
            >
              <div className="flex flex-wrap h-fit gap-10 xl:gap-40">
                <div className="flex flex-col gap-y-5 w-full sm:w-auto">
                  <Typography
                    text="Personal Information"
                    variant="h5"
                    customClass="font-semibold"
                  />
                  <personalInfoForm.Field
                    name="firstName"
                    children={(field) => (
                      <FormControl>
                        <Label>First Name</Label>
                        <Input
                          id="firstName"
                          name="firstName"
                          value={field.state.value}
                          onChange={(evt) => {
                            field.handleChange(evt.target.value);
                          }}
                          onBlur={(evt) => {
                            if (field.state.meta.isDirty) {
                              void personalInfoForm.handleSubmit();
                            }
                          }}
                          disabled={readonly}
                          errorMessageId={`err-${field.name}`}
                        />
                        <FormError id={`err-${field.name}`}>
                          {field.state.meta.errors?.join('\r')}
                        </FormError>
                      </FormControl>
                    )}
                  />
                  <personalInfoForm.Field
                    name="middleName"
                    children={(field) => (
                      <FormControl>
                        <Label>Middle Name</Label>
                        <Input
                          id="middlename"
                          name="middleName"
                          value={field.state.value as string}
                          onChange={(evt) => {
                            field.handleChange(evt.target.value);
                          }}
                          onBlur={() => {
                            if (field.state.meta.isDirty) {
                              void personalInfoForm.handleSubmit();
                            }
                          }}
                          disabled={readonly}
                          errorMessageId={`err-${field.name}`}
                        />
                        <FormError id={`err-${field.name}`}>
                          {field.state.meta.errors?.join('\r')}
                        </FormError>
                      </FormControl>
                    )}
                  />
                  <personalInfoForm.Field
                    name="lastName"
                    children={(field) => (
                      <FormControl>
                        <Label>Last Name</Label>
                        <Input
                          id="lastName"
                          name="lastName"
                          value={field.state.value}
                          onChange={(evt) => {
                            field.handleChange(evt.target.value);
                          }}
                          onBlur={() => {
                            if (field.state.meta.isDirty) {
                              void personalInfoForm.handleSubmit();
                            }
                          }}
                          hasError={field.state.meta.errors.length > 0}
                          disabled={readonly}
                          errorMessageId={`err-${field.name}`}
                        />
                        <FormError id={`err-${field.name}`}>
                          {field.state.meta.errors?.join('\r')}
                        </FormError>
                      </FormControl>
                    )}
                  />

                  <personalInfoForm.Field
                    name="dateOfBirth"
                    validators={{
                      onBlur: ({ value }: { value: string }) => {
                        if (!value) return null;
                        if (value.length > 1 && !dayjs(value).isValid()) {
                          return 'Invalid date';
                        }
                        return null;
                      },
                    }}
                    children={(field) => (
                      <FormControl>
                        <Label>Date of Birth</Label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <InputMask
                            component={Input}
                            name={field.name}
                            mask="MM/DD/YYYY"
                            separate
                            showMask
                            replacement={{ D: /\d/, M: /\d/, Y: /\d/ }}
                            value={field.state.value as string}
                            onChange={(event) => {
                              const value =
                                event.target.value === 'MM/DD/YYYY'
                                  ? ''
                                  : event.target.value;
                              field.handleChange(value ?? undefined);
                            }}
                            onBlur={(evt) => {
                              field.handleBlur();
                              if (
                                (dayjs(evt.target.value).isValid() ||
                                  evt.target.value === '') &&
                                field.state.meta.isDirty
                              ) {
                                void personalInfoForm.handleSubmit();
                              }
                            }}
                            disabled={readonly}
                          />
                        </LocalizationProvider>
                        <FormError id={`err-${field.name}`}>
                          {field.state.meta.errors?.join('\r')}
                        </FormError>
                      </FormControl>
                    )}
                  />
                  <personalInfoForm.Field
                    name="legalSex"
                    children={(field) => (
                      <FormControl className="relative">
                        <Label>Sex Assigned at Birth</Label>
                        <Select
                          value={field.state.value ?? ''}
                          defaultValue={field.state.value as string}
                          onValueChange={(value) => {
                            if (value === '') return;
                            field.handleChange(value as LegalSex);
                            void personalInfoForm.handleSubmit();
                          }}
                          disabled={readonly}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Select an option" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              <SelectItem value="female">Female</SelectItem>
                              <SelectItem value="male">Male</SelectItem>
                              <SelectItem value="other">Other</SelectItem>
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </FormControl>
                    )}
                  />
                  <personalInfoForm.Field
                    name="race"
                    children={(field) => (
                      <FormControl className="relative">
                        <Label>Race</Label>
                        <Select
                          value={field.state.value as string}
                          onValueChange={(value) => {
                            if (value === '') return;
                            field.handleChange(value);
                            if (field.state.meta.isDirty) {
                              void personalInfoForm.handleSubmit();
                            }
                          }}
                          disabled={readonly}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Select an option" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              <SelectItem value="black">
                                Black or African American
                              </SelectItem>
                              <SelectItem value="white">White</SelectItem>
                              <SelectItem value="eastasian">
                                East and Southeast Asian
                              </SelectItem>
                              <SelectItem value="southasian">
                                South and Central Asian
                              </SelectItem>
                              <SelectItem value="native">
                                Native American or Alaska Native
                              </SelectItem>
                              <SelectItem value="hawaiian">
                                Native Hawaiian or Pacific Islander
                              </SelectItem>
                              <SelectItem value="other">Other</SelectItem>
                              <SelectItem value="notsay">
                                Prefer not to say
                              </SelectItem>
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </FormControl>
                    )}
                  />
                  <personalInfoForm.Field
                    name="ethnicity"
                    children={(field) => (
                      <FormControl className="relative">
                        <Label>Ethnicity</Label>
                        <Select
                          value={field.state.value as string}
                          onValueChange={(value) => {
                            if (value === '') return;
                            field.handleChange(value);
                            if (field.state.meta.isDirty) {
                              void personalInfoForm.handleSubmit();
                            }
                          }}
                          disabled={readonly}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Select an option" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              <SelectItem value="hispanic">
                                Hispanic or Latino
                              </SelectItem>
                              <SelectItem value="nohispanic">
                                Not Hispanic or Latino
                              </SelectItem>
                              <SelectItem value="notsay">
                                Prefer not to say
                              </SelectItem>
                              <SelectItem value="other">Other</SelectItem>
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </FormControl>
                    )}
                  />
                  <personalInfoForm.Field
                    name="preferredLanguage"
                    children={(field) => (
                      <FormControl>
                        <Label>Preferred Language</Label>
                        <Input
                          id="preferredLanguage"
                          name="preferredLanguage"
                          value={field.state.value as string}
                          onChange={(evt) => {
                            field.handleChange(evt.target.value);
                          }}
                          onBlur={() => {
                            if (field.state.meta.isDirty) {
                              void personalInfoForm.handleSubmit();
                            }
                          }}
                          disabled={readonly}
                          errorMessageId={`err-${field.name}`}
                        />
                        <FormError id={`err-${field.name}`}>
                          {field.state.meta.errors?.join('\r')}
                        </FormError>
                      </FormControl>
                    )}
                  />
                </div>
                <div className="flex flex-col gap-y-5 w-full sm:w-auto">
                  <Typography
                    text="Personal Identity"
                    variant="h5"
                    customClass="font-semibold"
                  ></Typography>
                  <personalInfoForm.Field
                    name="preferredName"
                    children={(field) => (
                      <FormControl>
                        <Label>Preferred Name</Label>
                        <Input
                          id="preferredName"
                          name="preferredName"
                          value={field.state.value as string}
                          onChange={(evt) => {
                            field.handleChange(evt.target.value);
                          }}
                          onBlur={() => {
                            if (field.state.meta.isDirty) {
                              void personalInfoForm.handleSubmit();
                            }
                          }}
                          disabled={readonly}
                          errorMessageId={`err-${field.name}`}
                        />
                        <FormError id={`err-${field.name}`}>
                          {field.state.meta.errors?.join('\r')}
                        </FormError>
                      </FormControl>
                    )}
                  />
                  <personalInfoForm.Field
                    name="pronouns"
                    children={(field) => (
                      <FormControl className="relative">
                        <Label>Pronouns</Label>
                        <Select
                          value={field.state.value as string}
                          onValueChange={(value) => {
                            if (value === '') return;
                            field.handleChange(value);
                            if (field.state.meta.isDirty) {
                              void personalInfoForm.handleSubmit();
                            }
                          }}
                          disabled={readonly}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Select an option" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              <SelectItem value="she">
                                She / Her / Hers
                              </SelectItem>
                              <SelectItem value="he">He / Him / His</SelectItem>
                              <SelectItem value="they">
                                They / Them / Theirs
                              </SelectItem>
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </FormControl>
                    )}
                  />
                  <personalInfoForm.Field
                    name="genderIdentity"
                    children={(field) => (
                      <FormControl className="relative">
                        <Label>Gender Identity</Label>
                        <Select
                          value={field.state.value as string}
                          onValueChange={(value) => {
                            if (value === '') return;
                            field.handleChange(value);
                            if (field.state.meta.isDirty) {
                              void personalInfoForm.handleSubmit();
                            }
                          }}
                          disabled={readonly}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Select an option" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              <SelectItem value="woman">Woman</SelectItem>
                              <SelectItem value="man">Man</SelectItem>
                              <SelectItem value="transgender">
                                Transgender
                              </SelectItem>
                              <SelectItem value="nonbinary">
                                Nonbinary
                              </SelectItem>
                              <SelectItem value="other">Other</SelectItem>
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </FormControl>
                    )}
                  />
                </div>
              </div>
            </form>
          )}
        </div>
      </>
    );
  }
);

export default PersonalInformation;
